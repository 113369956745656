import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useDepartamentosList() {
  // Use toast
  const toast = useToast()

  const refDepartamentoListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'id', label: 'id', sortable: true },
    { key: 'titulo', label: 'título', sortable: true },
    {
      key: 'descripcion',
      label: 'descripcion',
      sortable: false,
    },
    { key: 'estado', label: 'estado', sortable: true },
    { key: 'num_empleados', label: 'Nº Empleados', sortable: true },
    { key: 'actions', label: '', thStyle: { minWidth: '150px' } },
  ]
  const perPage = ref(50)
  const totalDepartamentos = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)

  const dataMeta = computed(() => {
    const localItemsCount = refDepartamentoListTable.value ? refDepartamentoListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalDepartamentos.value,
    }
  })

  const refetchData = () => {
    refDepartamentoListTable.value.refresh()
  }
  const deleteData = id => {
    store.dispatch('app-departamento/deleteDepartamentos', id)
    refDepartamentoListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery], () => {
    refetchData()
  })

  const fetchDepartamentos = (ctx, callback) => {
    store
      .dispatch('app-departamento/fetchDepartamentos', {
        body: {
          search: searchQuery.value,
          paginate: perPage.value,
        },
        headers: {
          'X-Sort': `departamentos.${sortBy.value}`,
          'X-Sort-Order': isSortDirDesc.value ? 'desc' : 'asc',
        },
        query: {
          paginate: true,
          page: currentPage.value,
        },
      })
      .then(res => {
        const countEmpleados = res.countEmpleado

        res.data.forEach((value, key) => {
          const { titulo } = value
          if (titulo in countEmpleados) {
            // eslint-disable-next-line no-param-reassign
            value.num_empleados = countEmpleados[titulo]
          }
        })

        totalDepartamentos.value = res.data.length
        callback(res.data)
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching departamentos list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const listDepartamentos = () => new Promise((resolve, reject) => {
    store
      .dispatch('app-departamento/listDepartamentos', {
        body: {
          search: searchQuery.value,
        },
        headers: {
          'X-Sort': `departamentos.${sortBy.value}`,
          'X-Sort-Order': isSortDirDesc.value ? 'desc' : 'asc',
        },
        query: {},
      })
      .then(res => {
        const { total } = res
        totalDepartamentos.value = total
        const parsedDep = []

        if (res.data) {
          const filterActive = res.data.filter(el => el.estado === 1)
          if (res.data.length > filterActive.length) {
            totalDepartamentos.value -= (res.data.length - filterActive.length)
          }
          filterActive.forEach(element => {
            parsedDep.push({
              key: element.id,
              label: element.titulo,
            })
          })
        }
        resolve(parsedDep)
      })
      .catch(e => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching departamentos list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        reject(e)
      })
  })

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const parseDate = date => {
    let d = date.substring(0, 10)
    d = d.split('-').reverse().join('-')
    const t = date.substring(12, 19)
    return `${d} ${t}`
  }

  const resolveStatusVariant = status => {
    if (status === 0) return 'warning'
    if (status === 1) return 'success'
    if (status === 2) return 'secondary'
    return 'primary'
  }

  const resolveStatusText = status => {
    if (status === 0) return 'inactivo'
    if (status === 1) return 'activo'
    if (status === 2) return 'pendiente'
    return '-'
  }

  return {
    fetchDepartamentos,
    listDepartamentos,
    tableColumns,
    perPage,
    currentPage,
    totalDepartamentos,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refDepartamentoListTable,

    refetchData,
    deleteData,
    parseDate,
    resolveStatusVariant,
    resolveStatusText,
  }
}
