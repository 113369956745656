class AlbaranDetalle {
  constructor({
    id, fk_albaran, fk_material, descripcion, unit_entregadas, unit_solicitadas, descripcion_custom,
  } = {}) {
    this.id = id
    this.fk_albaran = fk_albaran
    this.fk_material = fk_material
    this.descripcion = descripcion
    this.descripcion_custom = descripcion_custom
    this.unit_entregadas = unit_entregadas
    this.unit_solicitadas = unit_solicitadas
  }
}

export default AlbaranDetalle
